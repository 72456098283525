import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Form, Button, Alert } from "react-bootstrap"
import sendMail from "../services/send-mail";

const ContactPage = () => {

    const [user_name, setUserName] = useState("");
    const [user_phone, setUserPhone] = useState("");
    const [user_email, setUserEmail] = useState("");
    const [user_message, setUserMessage] = useState("");
    const [successAlert, setSuccessAlert] = useState(false);
    const [failAlert, setFailAlert] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        let params = {
            reply_to: user_email,
            mail: user_email,
            message: user_message,
            name: user_name,
            phone: user_phone
        }

        sendMail(params, "LCQF_contact").then((result) => {
            setSuccessAlert(true)
        }, (error) => {
            setFailAlert(true)
        });
    }

    return (
        <Layout>
            <SEO title="Contact" />
            <Container>
                <h1 style={{ marginTop: "2rem" }}>Contact</h1>

                <Form onSubmit={sendEmail}>
                    <Form.Group controlId="name" name="user_name">
                        <Form.Control placeholder="Nom" onChange={e => setUserName(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="phone" >
                        <Form.Control placeholder="Téléphone" name="contact_number" onChange={e => setUserPhone(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="mail" >
                        <Form.Control type="email" placeholder="Addresse mail" name="user_email" onChange={e => setUserEmail(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="message" name="message">
                        <Form.Control as="textarea" rows={3} placeholder="Message" onChange={e => setUserMessage(e.target.value)} />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Envoyer
                    </Button>
                </Form>
                <div style={{ height: "1rem" }}></div>
                <Alert key="alert-success" variant="success" show={successAlert}>
                    Merci pour votre message!
                </Alert>
                <Alert key="alert-fail" variant="danger" show={failAlert}>
                    Une erreur s'est produite lors de l'envoi du message. Veuillez réessayer plus tard
                </Alert>
            </Container>

        </Layout >
    )
}

export default ContactPage